import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label, TextInput } from "flowbite-react";
import { IoIosAddCircle } from "react-icons/io";
import { IoRemoveCircle } from "react-icons/io5";
import { useAppContext } from "../context/AppContext";
import { isValidPhoneNumber, getCountryCallingCode } from "libphonenumber-js";

const Details = ({
  // billerLogo,
  productName,
  productPrice,
  productDesc,
  productType,
  productId,
  country,
  category,
  productCurrency,
  productTransactionAmount,
  productTransactionCurrency,
}) => {
  const { setCheckoutData } = useAppContext();
  const navigate = useNavigate();
  const [quantities, setQuantities] = useState({ [productName]: 1 });

  const handleIncrement = (productName) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productName]: prevQuantities[productName] + 1 || 1,
    }));
  };

  const handleDecrement = (productName) => {
    if (quantities[productName] > 1) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productName]: prevQuantities[productName] - 1,
      }));
    }
  };

  const quantity = quantities[productName] || 1;

  const subtotal = productTransactionAmount * quantity;

  const [recipient, setRecipient] = useState({
    name: "",
    contact: "",
  });

  const [contactError, setContactError] = useState("");

  const getCountryPhonePrefix = (countryCode) => {
    try {
      const callingCode = getCountryCallingCode(countryCode);
      return callingCode ? `+${callingCode}` : "";
    } catch (error) {
      console.error("Error getting country phone prefix:", error);
      return "";
    }
  };

  const code = country;
  const phonePrefix = getCountryPhonePrefix(code);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecipient((prevRecipient) => ({
      ...prevRecipient,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    const { contact } = recipient;
    console.log("Contact:", contact);

    if (!contact) {
      setContactError("Please enter an email or phone number.");
      return;
    }

    // Check if the input contains an "@" symbol
    if (contact.includes("@")) {
      // Check if the input is a valid email using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      console.log("Email regex:", emailRegex);
      console.log("Is valid email:", emailRegex.test(contact));
      if (!emailRegex.test(contact)) {
        setContactError("Please enter a valid email address.");
        return;
      }
    } 
    // else {
    //   // If the input does not contain an "@" symbol, assume it's a phone number
    //   if (!isValidPhoneNumber(contact)) {
    //     setContactError("Please enter a valid phone number.");
    //     return;
    //   }
    //   const userPrefix = contact.substring(0, 3); // Assuming the prefix length is 3
    //   console.log("User prefix:", userPrefix);
    //   console.log("Phone prefix:", phonePrefix);
    //   if (userPrefix !== phonePrefix) {
    //     setContactError("Please enter a phone number with the correct prefix.");
    //     return;
    //   }
    // }
    if(!isValidPhoneNumber(contact)){
        setContactError("Please enter a valid phone number.");
        return;
    }
    // If the input is valid, clear any previous error
    setContactError("");
  };

  const handleBuy = () => {
    // // Check if name and contact fields are filled out
    // if (!recipient.name || !recipient.contact) {
    //   alert("Please fill out all required fields.");
    //   return;
    // }

    // // Check if there's any error in the contact field
    // if (contactError) {
    //   alert(contactError);
    //   return;
    // }
    setCheckoutData({
      // billerLogo,
      productName,
      productPrice,
      quantity,
      subtotal,
      productType,
      recipientName: recipient.name,
      recipientContact: recipient.contact,
      productId,
      productCurrency,
      productTransactionAmount,
      productTransactionCurrency,
      country,
    });
    // Redirect to the checkout page
    navigate(`/${category}/checkout`);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="px-5 py-5 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3 w-full">
            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {/* <img src={billerLogo} className="mr-5" width={40} /> */}
                  <h2 className="text-gray-900 text-base title-font font-medium" style={{maxWidth: '70%'}}>
                    {productName} - {productCurrency} {productPrice}
                  </h2>
                </div>
                {/* <h2 className="text-gray-900 text-lg title-font font-medium">
                  {productCurrency} {productPrice}
                </h2> */}
                <h2 className="flex text-[#00875A] text-base title-font font-bold">
                  {productTransactionCurrency} <span className="ml-1">{productTransactionAmount}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3 w-full">
            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-lg title-font font-medium border-b pb-2 mb-2 w-full">
                  Description
                </h2>
              </div>
              <div className="flex-grow">
                <p className="leading-relaxed text-base">
                  {productDesc || "No product description"}
                </p>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3 w-full">
            {productType === "PINLESS" && (
              <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col mb-3">
                <div className="flex items-center">
                  <h2 className="text-gray-900 text-lg title-font font-medium border-b pb-2 mb-2 w-full">
                    Recipient
                  </h2>
                </div>
                <div className="flex-grow">
                  <form className="flex max-w-md flex-col gap-4">
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="name" value="Name" />
                      </div>
                      <TextInput
                        id="name"
                        type="text"
                        name="name"
                        onChange={handleInputChange}
                        value={recipient.name}
                        required
                        placeholder="John Doe"
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="contact" value="Email or Phone Number" />
                      </div>
                      <TextInput
                        id="contact"
                        type="text"
                        name="contact"
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        placeholder={`e.g. example@mail.com or ${phonePrefix}12345678`}
                        value={recipient.contact}
                        required
                      />

                      {contactError && (
                        <span className="text-red-500">{contactError}</span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}

            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col mb-3">
              <div className="flex items-center justify-between">
                <h2 className="text-gray-900 text-lg title-font font-medium">
                  Quantity
                </h2>
                <div className="flex items-center justify-evenly  bg-white py-2 px-5 rounded-lg">
                  <IoRemoveCircle
                    onClick={() => handleDecrement(productName)}
                    color={quantity === 1 ? "#C1C7D0" : "#00875A"}
                    className="cursor-pointer"
                  />
                  <span className="mx-2 font-bold text-black">{quantity}</span>
                  <IoIosAddCircle
                    color="#00875A"
                    className="cursor-pointer"
                    onClick={() => handleIncrement(productName)}
                  />
                </div>
              </div>
            </div>
            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col mb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-gray-900 text-lg title-font font-medium">
                  Subtotal
                </h2>
                <h2 className="text-[#00875A] text-lg title-font font-bold">
                  {productTransactionCurrency} {subtotal}
                </h2>
              </div>
            </div>
            {productType === "PINLESS" && (<button
              className={`w-full rounded-lg bg-[#00875A] py-3 text-white ${
                !recipient.name || !recipient.contact || contactError
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              onClick={handleBuy}
              disabled={!recipient.name || !recipient.contact || contactError}
            >
              Buy ({productTransactionCurrency} {subtotal})
            </button>
            )}
            {productType === "PIN" && (<button
              className={`w-full rounded-lg bg-[#00875A] py-3 text-white`}
              onClick={handleBuy}
              // disabled={!recipient.name || !recipient.contact || contactError}
            >
              Buy ({productTransactionCurrency} {subtotal})
            </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
