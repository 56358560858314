import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { IoCloseOutline, IoArrowBackSharp } from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";

export default function Navbar({ bannerImg, country, category, pageName }) {
  const [isSideMenuOpen, setMenu] = useState(false);

  const navlinks = [
    {
      label: "Home",
      link: `/${category}`,
    },
    {
      label: "Transactions",
      link: `/${category}/transactions`,
    },
  ];

  const handleBack = () => {
    setMenu(false);
    window.history.back();
  };

  const getBannerImageClass = () => {
    switch (category) {
      case "Telco":
        return "bg-banner-Telco";
      case "Wallet":
        return "bg-banner-default";
      case "Gaming":
        return "bg-banner-Gaming";
      case "Giftcard":
        return "bg-banner-Giftcard";
      default:
        return "bg-banner-default";
    }
  };

  return (
    <nav className="sticky top-0 bg-[#00875A] z-50">
      <div className="flex items-center justify-between py-2 lg:hidden">
        <button onClick={handleBack} className={`text-lg cursor-pointer px-3`}>
          <IoArrowBackSharp color="#fff" />
        </button>
        <h1 className="text-white text-md font-bold">{pageName}</h1>
        <div></div>
      </div>

      <div
        className={`text-white ${
          bannerImg ? bannerImg : getBannerImageClass()
        } bg-cover`}
      >
        <div className="w-full h-full bg-black opacity-80">
          <nav className="flex justify-between px-4 items-center py-6">
            <div className="flex items-center gap-8">
              <section className="flex items-center gap-4">
                {/* menu */}
                <FiMenu
                  onClick={() => setMenu(true)}
                  className="text-2xl cursor-pointer lg:hidden"
                />
                {/* logo */}
                <Link
                  to={`/${category}`}
                  className="hidden lg:block text-4xl font-mono"
                >
                  logo
                </Link>
              </section>
              {navlinks.map((d, i) => (
                <NavLink
                  key={i}
                  to={d.link}
                  className="hidden lg:block text-xl"
                  activeclassname="font-bold text-[#00875A]"
                  exact="true"
                >
                  {d.label}
                </NavLink>
              ))}
            </div>
            {/* back button */}

            {/* sidebar mobile menu */}
            <div
              className={`fixed h-full w-screen lg:hidden bg-black/50 backdrop-blur-sm top-0 right-0 -translate-x-full transition-all ${
                isSideMenuOpen ? "translate-x-0" : ""
              }`}
            >
              <section className="text-black bg-white flex-col absolute left-0 top-0 h-screen p-8 gap-8 z-50 w-56 flex  ">
                <IoCloseOutline
                  onClick={() => setMenu(false)}
                  className="mt-0 mb-8 text-3xl cursor-pointer"
                />
                <Link
                  to={`/${category}`}
                  className="text-2xl font-mono"
                >
                  logo
                </Link>
                {navlinks.map((d, i) => (
                  <NavLink
                    key={i}
                    to={d.link}
                    activeclassname="font-bold text-[#00875A]" // Change this to your desired active color
                    exact="true" // Only apply the activeclassname for exact="true" matches
                  >
                    {d.label}
                  </NavLink>
                ))}
              </section>
            </div>

            {/* last section */}
            <section className="flex items-center gap-4">
              {/* <MdOutlineSearch className="text-3xl" /> */}
              <div className="flex title-font font-medium items-center md:mb-0">
                <MdLocationPin className="text-xl" />
                <span className="ml-1 text-xl">{country}</span>
              </div>
            </section>
          </nav>
        </div>
      </div>
    </nav>
  );
}
