import React from "react";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri";

const StatusFailed = ({country, category}) => {
  const navigate = useNavigate();

  const handleCompletePayment = () => {
    navigate(`/${category}/checkout`);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="px-5 py-5 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3 w-full"></div>
          <div className="p-4 md:w-1/3 w-full">
            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col items-center justify-center">
              <RiCloseCircleFill size={100} color="#DE350B" />
              <h2 className="text-center text-gray-900 text-lg title-font font-medium my-3 w-full">
                Failed
              </h2>
              <p className="text-center text-base text-gray-900 mb-5 w-full">
                Sorry, your transaction has failed. Please try again later.
              </p>
             
              <button
                className="w-full rounded-lg bg-[#00875A] p-5 text-white mt-10"
                onClick={handleCompletePayment}
              >
                Retry
              </button>
            </div>
          </div>
          <div className="p-4 md:w-1/3 w-full"></div>
        </div>
      </div>
    </section>
  );
};

export default StatusFailed;
