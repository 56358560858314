import React from "react";
import PageNotFound from "../../components/PageNotFound";


const NotFound = () => {
  return (
    <div>
      <PageNotFound
        textError="We can't seem to find the page you're looking for. Please install the 1SEA App on your mobile phone"
        buttonText="Download 1SEA App"
      />
    </div>
  );
};

export default NotFound;