import React from "react";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";

const StatusSuccess = ({country,category}) => {
  const navigate = useNavigate();
  const { paymentSuccessData } = useAppContext();

  const handleCompletePayment = () => {
    // Redirect to the transaction page based on country and category
    navigate(`/${category}/transactions`);
  };
  const productType = paymentSuccessData.txn?.invoiceDetail.productType;
  return (
    <section className="text-gray-600 body-font">
      <div className="px-5 py-5 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3 w-full"></div>
          <div className="p-4 md:w-1/3 w-full">
            <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col items-center justify-center">
              <IoCheckmarkCircle size={100} color="#00875A" />
              <h2 className="text-center text-gray-900 text-lg title-font font-medium my-3 w-full">
                Success
              </h2>
              <p className="text-center text-base text-gray-900 mb-5 w-full">
                Your payment has been processed successfully
              </p>
              {/* <p className="text-center text-base text-gray-900 mb-5 w-full">
                {JSON.stringify(paymentSuccessData)}
              </p> */}
              <div className="w-full flex rounded-lg bg-white p-8 flex-col">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-gray-500 text-md title-font font-medium">
                    Product Name
                  </h2>
                  <h2 className="text-gray-500 text-md" style={{maxWidth: '50%'}}>{paymentSuccessData?.txn?.invoiceDetail?.productDesc}</h2>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-gray-500 text-md title-font font-medium">
                    Transaction ID
                  </h2>
                  <h2 className="text-gray-500 text-md style={{maxWidth: '50%'}}">{paymentSuccessData?.data?.txnId}</h2>
                </div>
                <div className="flex items-center justify-between border-b pb-2 mb-2">
                  <h2 className="text-gray-500 text-md title-font font-medium">
                    Transaction Date
                  </h2>
                  <h2 className="text-gray-500 text-md style={{maxWidth: '50%'}}">{moment(paymentSuccessData?.txn?.transactionDate).format("DD MM YYYY, h:mm:ss a")}</h2>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-gray-500 text-md title-font font-medium">
                    Total Amount
                  </h2>
                  <h2 className="text-gray-500 text-md">{paymentSuccessData?.data?.Product?.transactionCurrency} {paymentSuccessData?.txn?.amountInCentsPaid /100}</h2>
                </div>
                
                {productType === "PIN" && (
                <p className="text-center text-base text-gray-900 mt-5 w-full font-bold">
                  PIN and Instructions to redeem your product has been
                  sent to {paymentSuccessData?.data?.detail?.sentEmail}
                </p>)}
              </div>
              <button
                className="w-full rounded-lg bg-[#00875A] p-5 text-white mt-10"
                onClick={handleCompletePayment}
              >
                Done
              </button>
            </div>
          </div>
          <div className="p-4 md:w-1/3 w-full"></div>
        </div>
      </div>
    </section>
  );
};

export default StatusSuccess;
