import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Transaction from "../../components/Transaction";
import { useAppContext } from "../../context/AppContext";

const Transactions = ({country, category}) => {
  const { getTransactionList } = useAppContext();
  // const [transactionListString, setTransactionListString] = useState("");
  const [transactionsList, setTransactionsList] = useState([]);

  useEffect(() => {
    const fetchTransactionList = async () => {
      try {
        const transactionList = await getTransactionList(country, category);
        // const transactionListString = JSON.stringify(
        //   transactionList?.data,
        //   null,
        //   2
        // );
        // setTransactionListString(transactionListString);
        setTransactionsList(transactionList?.data);
      } catch (error) {
        // Handle errors
        console.error(
          "Error fetching transaction list in TransactionPage:",
          error
        );
      }
    };

    // Call the function when the component mounts
    fetchTransactionList();
  }, [getTransactionList, country, category]);

  return (
    <>
      <Navbar country={country} category={category} pageName="Transactions"/>
      {/* <p>Transaction List:</p>
      <pre>{transactionListString}</pre> */}
      <Transaction data={transactionsList} />
    </>
  );
};

export default Transactions;
