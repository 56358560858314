import React, {  useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

function ProductProcess({ customOrderId }) {
  const { isLoading, pollingTransaction, setPaymentSuccessData } =
    useAppContext();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams()
  const transactionStatus = searchParams.get("status");
  useEffect(()=>{
    if(transactionStatus === "failed"){
      navigate("/status-failed");
    }
  },[navigate, transactionStatus]);

  useEffect(() => {
    const pollInterval = setInterval(async() => {
     await pollingTransaction(customOrderId)
        .then(async(data) => {
          setPaymentSuccessData(data);
          // Check if transaction is successful
          if (data.data.status === "success") {
            // Transaction successful, redirect to status success page
            navigate("/status-success");
            // Stop polling
            clearInterval(pollInterval);
          }
          if (data.data.status === "failed"){
            navigate("/status-failed");
            // Stop polling
            clearInterval(pollInterval);
          }
        })
        .catch((error) => {
          // Handle polling error
          console.error("Error polling transaction:", error);
        });
    }, 5000); // Poll every 5 seconds (adjust as needed)

    // Clean up on unmount
    return () => clearInterval(pollInterval);
  }, [customOrderId, navigate, pollingTransaction, setPaymentSuccessData]);

  return (
    <div
      className="flex justify-center items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      {isLoading && (
        <ClipLoader
          color={"#00875A"}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </div>
  );
}

export default ProductProcess;
