import React from "react";
import { AppProvider } from "./context/AppContext";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProductPage from "./pages/Product/ProductPage";
import ProductDetails from "./pages/Product/ProductDetails";
import ProductCheckout from "./pages/Product/ProductCheckout";
import ProductProcess from "./pages/Product/ProductProcess";
import Transactions from "./pages/Product/Transactions";
import NotFound from "./pages/PageError/NotFound";
import StatusSuccess from "./pages/StatusPayment/StatusSuccess";
import StatusFailed from "./pages/StatusPayment/StatusFailed";
import ErrorPage from "./pages/PageError/ErrorPage";

function App() {
  // const { country } = useAppContext();
  const categories = ["Telco", "Wallet", "Gaming", "Giftcard"];

  const localStorageCountry = localStorage.getItem("country");
  console.log(`localStorage country`, localStorageCountry);
  // Custom component to check customOrderId in URL parameters and navigate to ProductProcess if present
  const RedirectToProductProcess = ({ country, category }) => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const customOrderId = params.get("customOrderId");

    // Check if customOrderId is present and return ProductProcess if true
    return customOrderId ? (
      <ProductProcess customOrderId={customOrderId} />
    ) : (
      <StatusFailed country={localStorageCountry} category={category} />
    );
  };
  return (
    <AppProvider>
      <Routes>
        {categories.map((category, country, index) => (
          <React.Fragment key={index}>
            <Route
              path={`/${category}`}
              element={<ProductPage category={category} country={localStorageCountry} />}
            />
            <Route
              path={`/${category}/:productName/:productItemCode`}
              element={<ProductDetails category={category} country={localStorageCountry} />}
            />
            <Route
              path={`/${category}/checkout`}
              element={<ProductCheckout category={category} country={localStorageCountry} />}
            />
            <Route
              path="/"
              element={
                <RedirectToProductProcess category={category} country={localStorageCountry}/>
              }
            />
            <Route
              path={`/${category}/transactions`}
              element={<Transactions category={category} country={localStorageCountry} />}
            />
            <Route
              path="status-success"
              element={<StatusSuccess category={category} country={localStorageCountry} />}
            />
            <Route
              path="status-failed"
              element={<StatusFailed category={category} country={localStorageCountry} />}
            />
          </React.Fragment>
        ))}
        <Route path="page-not-found" element={<NotFound />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
