import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const [checkoutData, setCheckoutData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [profileKey, setProfileKey] = useState(null);
  const [is1SEAApp, setIs1SEAApp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [t05PayUrl, setT05PayUrl] = useState(null);
  const [paymentSuccessData, setPaymentSuccessData] = useState(null);
  const [country, setCountry] = useState(null);

  const setCheckoutDataHandler = (data) => {
    setCheckoutData(data);
  };
  const getAccessToken = async () => {
    setIsLoading(true);
    try {
      const storedProfileKey = localStorage.getItem("1sea-profile-key");
      const storedIs1SEAApp = localStorage.getItem("is1SEAApp");

      // Convert storedIs1SEAApp to a boolean using Boolean constructor
      const is1SEAAppBoolean = Boolean(storedIs1SEAApp);

      console.log("Stored is1SEAApp (boolean):", is1SEAAppBoolean);

      if (storedProfileKey && storedIs1SEAApp) {
        setProfileKey(storedProfileKey);
        setIs1SEAApp(is1SEAAppBoolean);

        const response = await axios.post(`${BASE_URL}/oauth/token`, {
          profileKey: storedProfileKey,
          is1SEAApp: is1SEAAppBoolean,
        });

        const { token } = response.data;
        setIsLoading(false);
        setAccessToken(token);

        return accessToken;
      } else {
        console.error("Profile key or is1SEAApp not found.");
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  const getProductList = async (country, category, selectedProducts) => {
    try {
      // Ensure access token is available
            if (!accessToken) {
        await getAccessToken();
      }
      let requestBody;
      if(selectedProducts === undefined){
        requestBody = {
          profileKey,
          is1SEAApp,

        };
      }else{
        requestBody = {
          profileKey,
          is1SEAApp,
          ...(selectedProducts.length > 0 && {
            billerArray: Array.from(selectedProducts),
          }),
        };
      }
      const response = await axios.post(
        `${BASE_URL}/product/list/${country}/${category}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Product List:", response.data);

      // Return the product list or handle it based on your needs
      return response.data;
    } catch (error) {
      console.error("Error getting product list:", error);
      throw error;
    }
  };

  const transactionPayment = async (
    amountInCents,
    transactionAmount,
    productId,
    currency,
    phoneNumber,
    email
  ) => {
    setIsLoading(true);
    try {
      // Ensure access token is available
      if (!accessToken) {
        await getAccessToken();
      }

      const requestBody = {
        profileKey,
        is1SEAApp,
        amountInCents,
        // email,
        transactionAmount,
        productId,
        currency,
        // phoneNumber
      };

      // Add phoneNumber and email to the requestBody if they are provided
      if (phoneNumber) {
        requestBody.phoneNumber = phoneNumber;
      }

      if (email) {
        requestBody.email = email;
      }

      const response = await axios.post(
        `${BASE_URL}/transaction/payment`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsLoading(false);
      if (response.data.success) {
        window.location.href = response.data.data;
        setT05PayUrl(response.data.data);
      } else {
        navigate("/status-failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error transaction payment:", error);
      throw error;
    }
  };

  const pollingTransaction = async (customOrderId) => {
    setIsLoading(true);
    try {
      console.log(`pollingTransaction start`);
      // Ensure access token is available
      if (!accessToken) {
        await getAccessToken();
      }

      const requestBody = {
        profileKey,
        is1SEAApp,
        customOrderId,
      };

      const response = await axios.post(
        `${BASE_URL}/product/transaction/poll`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsLoading(false);
      console.log(`pollingTransaction: `, response.data);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error("Error polling transaction:", error);
      throw error;
    }
  };

  const getTransactionList = async (country, category) => {
    try {
      // Ensure access token is available
      if (!accessToken) {
        await getAccessToken();
      }

      const requestBody = {
        profileKey,
        is1SEAApp,
      };

      const response = await axios.post(
        `${BASE_URL}/transaction/list/${country}/${category}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Transaction List:", response.data);

      // Return the product list or handle it based on your needs
      return response.data;
    } catch (error) {
      console.error("Error getting transaction list:", error);
      throw error;
    }
  };

  useEffect(() => {
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (is1SEAApp === false) {
      navigate("/page-not-found");
    }
  }, [is1SEAApp, navigate]);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        checkoutData,
        setCheckoutData: setCheckoutDataHandler,
        accessToken,
        profileKey,
        is1SEAApp,
        getProductList,
        transactionPayment,
        t05PayUrl,
        pollingTransaction,
        setPaymentSuccessData,
        paymentSuccessData,
        getTransactionList,
        country,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
