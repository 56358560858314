import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { Dropdown } from "flowbite-react";
import { useAppContext } from "../context/AppContext";

function Products({ products, category, country, onSelectedProductsChange, selectedProducts }) {
  const { isLoading } = useAppContext();
  // const [selectedProducts, setSelectedProducts] = useState([]);

  console.log(`selectedProducts: `, selectedProducts);

  //   Search Bar
  const filterData = (data, query) => {
    if (!query) {
      return data;
    }

    return data.filter((x) =>
      x?.productList?.some((item) =>
        item?.description?.toLowerCase().includes(query.toLowerCase()) ||
        item?.billingAmount?.toLowerCase().includes(query.toLowerCase())
      )
    );
  };
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filterData(products, searchQuery);

  //Filtering Button
  const handleProductCheckboxChange = (productName) => {
    const updatedSelectedProducts = selectedProducts.includes(productName)
      ? selectedProducts.filter((product) => product !== productName)
      : [...selectedProducts, productName];

    // setSelectedProducts(updatedSelectedProducts);

    onSelectedProductsChange(updatedSelectedProducts);
  };

  const renderProductItems = (product) => {
    return product.productList.map((item, index) => (
      <Link
        to={`/${category}/${product.name}/${item.code}`}
        key={index}
        className="flex items-center justify-between text-gray-600 mb-4 bg-[#F4F5F7] rounded-md p-2 cursor-pointer"
        style={{ cursor: "pointer" }}
      >
        <div style={{ maxWidth: "70%" }}>
          <span className="font-bold">
            {item.description} -{" "}
            <span className="font-bold ">
              {product.currency} {item.sellingPrice}
            </span>
          </span>
        </div>
        <div className="flex flex-col items-end">
          <span className="font-bold text-[#00875A]">
            {item.transactionCurrency}{" "}
            {Math.ceil(item.transactionAmount * 100) / 100}
          </span>
        </div>
      </Link>
    ));
  };

  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="px-5 py-10 mx-auto ">
        <div className="flex flex-col text-center w-full mb-10 ">
          <div className="flex justify-between space-x-6">
            <form className="w-full max-w-md">
              <div className="relative flex items-center text-gray-400 focus-within:text-[#00875A]">
                <IoSearch className="absolute ml-3 w-5 h-5 pointer-events-none" />
                <input
                  type="text"
                  placeholder="Search products..."
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  className="w-full py-2 pr-3 pl-10 font-semibold placeholder-gray-500 text-black rounded-2xl border-2 border-gray-300 focus:outline-none focus:border-[#00875A] focus:ring-2 focus:ring-[#00875A] transition-all"
                />
              </div>
            </form>
            {filteredData?.length > 0 && (
              <div>
                <Dropdown
                  label="Filter"
                  dismissOnClick={false}
                  style={{ backgroundColor: "#00875A" }}
                >
                  {filteredData?.map((product, index) => (
                    <Dropdown.Item key={index}>
                      <input
                        id={`${product.name}-${index}`} // Concatenate index with product name
                        type="checkbox"
                        value={product.name}
                        checked={selectedProducts.includes(product.name)}
                        onChange={() =>
                          handleProductCheckboxChange(product.name)
                        }
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        htmlFor={`${product.name}-${index}`} // Use the same concatenated ID here
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                      >
                        {product.name} ({product.productList.length})
                      </label>
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div
            className={`flex flex-wrap -m-4 ${
              filteredData?.length === 0 ? "justify-center" : null
            } `}
          >
            {filteredData?.length > 0 ? (
              filteredData?.map((product, index) => (
                <div key={index} className="p-4 xl:w-1/4 md:w-1/2 w-full">
                  <div className="h-full p-4 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                    <div
                      className={`flex items-center justify-center py-2 my-4 border-b border-gray-200 bg-[#00875A]`}
                    >
                      {/* <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full text-white flex-shrink-0">
                        <img src={product.pLogo} alt={product.name} />
                      </div> */}
                      <h2 className="text-white text-lg title-font font-medium">
                        {product.name}
                      </h2>
                    </div>
                    {renderProductItems(product)}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-lg text-gray-500 text-center">
                No data available
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default Products;
