import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Details from "../../components/Details";
import { useAppContext } from "../../context/AppContext";

const ProductDetails = ({country, category}) => {
  const { productName, productItemCode } = useParams();
  const { getProductList } = useAppContext();
  const [productListString, setProductListString] = useState("");
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const productList = await getProductList(country, category);
        const productListString = JSON.stringify(productList?.data, null, 2);
        setProductListString(productListString);
        setProductsList(productList?.data);
        console.log("Product List in ProductPage:", productList);
      } catch (error) {
        // Handle errors
        console.error("Error fetching product list in ProductPage:", error);
      }
    };

    // Call the function when the component mounts
    fetchProductList();
  }, [getProductList, country, category]);

  // Find the product with the matching product name
  const product = productsList?.find((p) => p.name === productName);

  if (!product) {
    return <div>Loading...</div>;
  }

  // Find the product item with the matching code
  const productItem = product.productList.find(
    (item) => item.code === productItemCode
  );

  if (!productItem) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar country={country} category={category} pageName={`${productItem.description} - ${product.currency} ${productItem.sellingPrice}`}  />
      <Details
        country={country}
        category={category}
        // billerLogo={product.pLogo}
        productId={productItem._id}
        productType={productItem.type}
        productName={productItem.description}
        productPrice={productItem.sellingPrice}
        productCurrency={product.currency}
        productTransactionCurrency={productItem.transactionCurrency}
        productTransactionAmount={Math.ceil(productItem.transactionAmount * 100) / 100}
        productDesc={productItem.usageDesc}
      />
    </div>
  );
};

export default ProductDetails;
