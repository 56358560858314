import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Products from "../../components/Products";
import { useAppContext } from "../../context/AppContext";

function ProductPage({ category, country }) {
  const { accessToken, profileKey, is1SEAApp, getProductList } =
    useAppContext();
  const [productListString, setProductListString] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [selectedProductsString, setSelectedProductsString] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSelectedProductsChange = (selectedProducts) => {
    const selectedProductsStr = JSON.stringify(selectedProducts);
    setSelectedProductsString(selectedProductsStr);
    setSelectedProducts(selectedProducts)
  };


  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const productList = await getProductList(country, category, selectedProducts);
        const productListString = JSON.stringify(productList?.data, null, 2);
        setProductListString(productListString);
        setProductsList(productList?.data);
        console.log("Product List in ProductPage:", productList);
      } catch (error) {
        // Handle errors
        console.error("Error fetching product list in ProductPage:", error);
      }
    };

    fetchProductList();
  }, [getProductList, country, category, selectedProducts]);

  return (
    <>
      <Navbar
        country={country}
        category={category}
        pageName={`${category} Products`}
      />
      {/* <p>Access token: {accessToken}</p>
      <p>Profile Key: {profileKey}</p>
      <p>Product List:</p>
      <pre>{productListString}</pre> */}
      {/* <p>Selected Products: {selectedProductsString}</p> */}
      <Products category={category} products={productsList} country={country} onSelectedProductsChange={handleSelectedProductsChange} selectedProducts={selectedProducts} />
    </>
  );
}

export default ProductPage;
