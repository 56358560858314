import React from "react";
import PageNotFound from "../../components/PageNotFound";


const ErrorPage = () => {
  return (
    <div>
      <PageNotFound
        textError="Opps, Sorry we can't seem to find the page you're looking for. "
        buttonText="Close"
      />
    </div>
  );
};

export default ErrorPage;