import React from 'react'
import Checkout from '../../components/Checkout'
import Navbar from '../../components/Navbar'

const ProductCheckout = ({country, category}) => {
  return (
    <>
    <Navbar  country={country} category={category} pageName="Checkout" />
    <Checkout/>
    </>
  )
}

export default ProductCheckout
