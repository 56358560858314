import React, { useState } from "react";
import { Label, TextInput } from "flowbite-react";
import { useAppContext } from "../context/AppContext";
// import useClipboard from "react-use-clipboard";
import { getCountryCallingCode } from "libphonenumber-js";
import ClipLoader from "react-spinners/ClipLoader";

const currencyNumericCodes = {
  MYR: 458,
  SGD: 702,
  VND: 704,
  PHP: 608,
  USD: 840,
};

const Checkout = () => {
  const { isLoading, checkoutData, transactionPayment } =
    useAppContext();

  const getCountryPhonePrefix = (countryCode) => {
    try {
      const callingCode = getCountryCallingCode(countryCode);
      return callingCode ? `+${callingCode}` : "";
    } catch (error) {
      console.error("Error getting country phone prefix:", error);
      return "";
    }
  };

  const code = checkoutData.country;
  const phonePrefix = getCountryPhonePrefix(code);

  const isEmail = (contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(contact);
  };

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePayClick = async () => {
    try {
      let emailToSend =
        checkoutData.productType === "PIN"
          ? email
          : isEmail(checkoutData.recipientContact)
          ? checkoutData.recipientContact
          : "";

      let phoneToSend = "";
      if (checkoutData.productType === "PINLESS") {
        if (checkoutData.recipientContact.startsWith(phonePrefix)) {
          phoneToSend = checkoutData.recipientContact;
        } else if (isEmail(checkoutData.recipientContact)) {
          emailToSend = checkoutData.recipientContact;
        } else {
          throw new Error("Invalid recipient contact.");
        }
      }

      let currencyNumericCode;
      if (checkoutData.productCurrency === "SGD") {
        currencyNumericCode = currencyNumericCodes.SGD;
      } else {
        currencyNumericCode = currencyNumericCodes.USD;
      }

      if (!currencyNumericCode) {
        throw new Error(
          "Numeric code not found for currency: " + checkoutData.productCurrency
        );
      }

      const totalAmount =
        (Math.ceil(checkoutData.productTransactionAmount * 100) / 100) *
        checkoutData.quantity;

      const totalAmountInCents = Math.ceil(
        checkoutData.productTransactionAmount * 100
      );

      await transactionPayment(
        totalAmountInCents,
        // 1,
        totalAmount,
        checkoutData.productId,
        currencyNumericCode,
        phoneToSend,
        emailToSend
      );
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  // const [isCopied, setCopied] = useClipboard(t05PayUrl);

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <ClipLoader
            color={"#00875A"}
            loading={isLoading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <section className="text-gray-600 body-font">
        <div className="px-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3 w-full"></div>
            <div className="p-4 md:w-1/3 w-full">
              <div className="flex rounded-lg bg-[#F4F5F7] p-8 flex-col">
                <div className="flex items-center mb-3">
                  <h2 className="text-gray-900 text-lg title-font font-medium border-b pb-2 mb-2 w-full">
                    Summary
                  </h2>
                </div>
                {checkoutData?.productType === "PINLESS" && (
                  <div className="flex rounded-lg bg-white p-8 flex-col mb-3">
                    <div className="flex items-center">
                      <h3 className="text-gray-900 text-md title-font font-medium border-b pb-2 mb-2 w-full">
                        Sending to
                      </h3>
                    </div>
                    <div className="flex items-center">
                      <p className="text-gray-900 text-md  w-full">
                        {checkoutData.recipientName}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-gray-900 text-md w-full">
                        {checkoutData.recipientContact}
                      </p>
                    </div>
                  </div>
                )}

                <div className="flex rounded-lg bg-white p-8 flex-col w-full mb-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex flex-col">
                        <h2 className="text-gray-900 text-sm title-font font-medium" style={{maxWidth: '80%'}}>
                          {checkoutData.productName} -{" "}
                          {checkoutData.productCurrency}{" "}
                          {checkoutData.productPrice}
                        </h2>
                        <p className="text-gray-900 text-sm">
                          {checkoutData.productTransactionCurrency}{" "}
                          {checkoutData.productTransactionAmount}
                        </p>
                      </div>
                    </div>
                    <h2 className="flex text-gray-900 text-sm title-font font-bold bg-[#F4F5F7] px-3 py-2 rounded-lg ">
                      x <span className="ml-1">{checkoutData.quantity}</span>
                    </h2>
                  </div>
                </div>

                <div className="flex rounded-lg bg-white p-5 flex-col mb-5">
                  <div className="flex items-center justify-between">
                    <h2 className="text-gray-900 text-md title-font font-medium">
                      Subtotal
                    </h2>
                    <h2 className="text-gray-900 text-md title-font font-bold">
                      {checkoutData.productTransactionCurrency}{" "}
                      {checkoutData.subtotal}
                    </h2>
                  </div>
                </div>

                {checkoutData.productType === "PIN" && (
                  <div className="flex rounded-lg bg-white p-8 flex-col">
                    <div className="flex items-center">
                      <h2 className="text-gray-900 text-md title-font font-medium border-b pb-2 mb-5 w-full">
                        Send PIN or code to your email
                      </h2>
                    </div>
                    <div className="flex-grow">
                      <form className="flex max-w-md flex-col gap-4">
                        <div>
                          <div className="mb-2 block">
                            <Label htmlFor="email" value="Email" />
                          </div>
                          <TextInput
                            id="email"
                            type="text"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            placeholder={'e.g. example@mail.com'}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                <button
                  className="w-full rounded-lg bg-[#00875A] py-3 text-white mt-5 relative"
                  onClick={handlePayClick}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span>Processing...</span>
                  ) : (
                    <span className="font-bold">
                      Pay ({checkoutData.productTransactionCurrency}{" "}
                      {checkoutData.subtotal})
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className="p-4 md:w-1/3 w-full"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
