import React, { useState } from "react";
import moment from "moment";

const Transaction = ({ data }) => {
  // State to manage the visibility of PINs for each item
  const [pinVisibility, setPinVisibility] = useState([]);

  // Function to toggle the visibility of a specific PIN
  const togglePinVisibility = (index) => {
    const newVisibility = [...pinVisibility];
    newVisibility[index] = !newVisibility[index];
    setPinVisibility(newVisibility);
  };

  // Grouping transactions by transaction date
  const transactionsByDate = data.reduce((acc, item) => {
    const date = moment(item.transactionDate).format("D MMMM YYYY");
    acc[date] = acc[date] || [];
    acc[date].push(item);
    return acc;
  }, {});

  // Check if data is an empty array
  if (data.length === 0) {
    return (
      <section className="text-gray-600 body-font" style={{ height: "80vh" }}>
        <div className="container mx-auto flex justify-center items-center h-full">
          <p className="text-gray-500 text-lg font-bold mb-3 text-center">
            No Transaction Data Available
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="text-gray-600 body-font">
      <div className="px-5 py-10 mx-auto">
        <div className="flex flex-wrap -m-4">
          {Object.entries(transactionsByDate).map(
            ([date, transactions], idx) => (
              <div key={idx} className="w-full mb-4">
                <h1 className="text-gray-500 text-lg font-bold mb-3 px-4">
                  {date}
                </h1>
                {transactions.map((item, index) => (
                  <div
                    key={index}
                    className="p-4 md:w-1/3 w-full border-b pb-2 mb-2"
                  >
                    <div className="flex items-center mb-5">
                      {/* <img src={Logo} className="pr-3" /> */}
                      <div className="w-full flex flex-col ">
                        <h2 className="text-black text-md title-font font-medium">
                          {item.invoiceDetail.billerName}
                        </h2>
                        <div className="flex items-center justify-between ">
                          <h2 className="flex flex-col text-black text-md title-font font-bold">
                            {item.invoiceDetail.productDesc}
                            <span className="text-[#00875A] font-bold">
                              {item.currencyCode} {item.amountInCentsPaid / 100}
                            </span>
                          </h2>

                          {/* Conditionally render the Show button only if the product is PIN */}
                          {item.invoiceDetail.productType === "PIN" && (
                            <div>
                              <button
                                className="w-full rounded-lg bg-[#00875A] px-2 py-1 text-white relative text-sm"
                                onClick={() => togglePinVisibility(index)}
                              >
                                {pinVisibility[index] ? "Hide" : "Show PIN"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Render PIN only if pinVisibility for this item is true */}
                    {pinVisibility[index] &&
                      item.invoiceDetail.productType === "PIN" && (
                        <div className="flex items-center mb-5">
                          {item.detail !== undefined && (
                          <p className="text-gray-500 text-md title-font font-medium">
                            PIN: {item.detail.pin}
                          </p>)}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Transaction;
