import React from 'react';
import Error from '../assets/004.jpg';

const PageNotFound = ({textError, buttonText}) => {
  return (
    <div>
      <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="object-cover object-center rounded" alt="hero" src={Error} />
    </div>
    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
      <p className="mb-8 leading-relaxed">{textError}</p>
      {/* <div className="flex justify-center">
        <button className="inline-flex text-white bg-[#00875A] border-0 py-2 px-6 focus:outline-none hover:bg-[#00875A] rounded text-lg">{buttonText}</button>
      </div> */}
    </div>
  </div>
</section>
    </div>
  )
}

export default PageNotFound